<template>
	<div id="queueWait">
		<p>
			Du er nu i kø til lægevagtens chat for døve, hørehæmmede og talehæmmede.
			Du vil hurtigst muligt komme i kontakt med lægen.
		</p>
		<p id="queueInfo">
			Du er nummer
			<strong>{{ queuePosition }}</strong> i køen.
			<br />Der er i alt
			<strong>{{ queueLength }}</strong> i kø.
			<br />Du har været i kø i:
			<strong>{{ calculateWaitTime(joinedAt) }}</strong>.
		</p>
		<div v-if="patientDescription.length > 1" id="patientBox">
			<h2>Symptombeskrivelse</h2>
			<p>{{ patientDescription }}</p>
		</div>
		<p>
			Hvis du lukker vinduet eller navigerer væk, så mister du din plads i køen og må starte forfra.
			Hvis du ønsker at forlade køen skal du blot lukke siden.
		</p>
	</div>
</template>

<script>
import DataBroker from './../helpers/DataBroker.js';
import EventSystem from '../helpers/EventSystem.js';

export default {
	name: 'QueueWait',
	props: ['patientDescription'],
	data: function() {
		return {
			joinedAt: this.joinedAt,
			queueLength: this.queueLength,
			queuePosition: this.queuePosition
		};
	},
	created() {
		this.joinedAt = new Date();
		EventSystem.on('queue-updated', message => {
			this.updateQueueStatus(message.queueLength, message.queuePosition);
		});

		this.getQueueStatus();

		// Strictly speaking, updating the queue status like this isn't necessary. Updates should be
		// rolling in automatically through the event system. We primarily do this to keep the client
		// active in the queue, and to ensure the websocket doesn't time out.
		// Every 28 seconds, because if memory serves right, Linux likes closing idle sockets after
		// 30 seconds.
		setInterval(this.getQueueStatus, 1000 * 28);

		// Force recalculation of wait time every 10 seconds.
		setInterval(() => this.$forceUpdate(), 1000 * 10);
	},
	methods: {
		async updateQueueStatus(queueLength, queuePosition) {
			this.queueLength = typeof queueLength === 'number' ? queueLength : '?';
			this.queuePosition = typeof queuePosition === 'number' ? queuePosition : '?';
		},
		async getQueueStatus() {
			const { queueLength, queuePosition } = await DataBroker.send('get-queue-status');
			this.updateQueueStatus(queueLength, queuePosition);
		},
		calculateWaitTime(joinedAt) {
			const minutes = Math.round((new Date() - new Date(joinedAt)) / 1000 / 60);
			return minutes < 1
				? 'under et minut'
				: minutes === 1
				? '1 minut'
				: Math.round(minutes) + ' minutter';
		}
	}
};
</script>

<style scoped>
div#queueWait {
	margin: 0 10px;
}

div#queueWait p#queueInfo,
div#queueWait div#patientBox {
	background: #fff;
	text-align: center;
	padding: 20px 0;
	margin: 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

div#queueWait div#patientBox {
	text-align: left;
	padding: 20px;
	width: 760px;
	max-width: calc(100vw - 80px);
	word-wrap: break-word;
	hyphens: auto;
	overflow: hidden;
}

div#queueWait div#patientBox h2,
div#queueWait div#patientBox p {
	margin: 0;
}
div#queueWait div#patientBox h2 {
	font-size: 100%;
	padding-bottom: 5px;
}
</style>