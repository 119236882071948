<template>
	<div id="app">
		<header>
			<a href="/">
				<h1>
					<span>Vagtlægekonsultation for</span>
					<span>døve, hørehæmmede og talehæmmede</span>
				</h1>
				<img src="/midt-logo.svg" id="logo" />
			</a>
		</header>
		<div id="contentWrapper">
			<EnterCPRView
				v-if="!inQueue && !inChat"
				@join-queue="handleJoinQueue"
				v-bind:error="enterCPRError"
			/>
			<QueueWaitView v-if="inQueue" v-bind:patientDescription="patientDescription" />
			<ChatView v-if="inChat" v-bind:patientDescription="patientDescription" />
		</div>
	</div>
</template>

<script>
import DataBroker from './helpers/DataBroker.js';
import EventSystem from './helpers/EventSystem.js';

import EnterCPRView from './views/EnterCPR.vue';
import QueueWaitView from './views/QueueWait.vue';
import ChatView from './views/Chat.vue';

export default {
	name: 'App',
	components: {
		EnterCPRView,
		QueueWaitView,
		ChatView
	},
	data: function() {
		return {
			patientDescription: this.patientDescription,
			inQueue: this.inQueue,
			inChat: this.inChat,
			enterCPRError: this.enterCPRError
		};
	},
	created() {
		this.inQueue = false;
		this.inChat = false;
		this.consultationEnded = false;

		EventSystem.on('start-chat', () => {
			this.inQueue = false;
			this.inChat = true;
		});

		EventSystem.on('disconnect', () => {
			this.inQueue = false;
			if (!this.inChat) {
				this.enterCPRError =
					'Du har mistet forbindelsen til serveren, og har derfor også mistet din plads ' +
					'i køen. Sørg for at holde vinduet aktivt, imens du er i kø.';
			}
		});
	},
	methods: {
		async handleJoinQueue(cprNumber, description) {
			await DataBroker.send('join-queue', { cprNumber, description });
			this.patientDescription = description;
			this.inQueue = true;
			this.error = undefined;
		}
	}
};
</script>

<style>
* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body,
html {
	margin: 0;
	padding: 0;
	height: 100%;
}

#app {
	height: calc(100% - 120px);
}

body {
	background: #f0f0f0;
	font: 120% sans-serif;
}

#contentWrapper {
	display: flex;
	height: 100%;
	max-width: 800px;
	margin: 0 auto;
}

input,
textarea,
button {
	font-size: 100%;
	padding: 6px 10px;
}

button {
	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

button:focus {
	outline: none;
}

button:hover {
	cursor: pointer;
}

header {
	background: #903;
	padding: 15px 0 15px 25px;
	height: 90px;
}

header h1 {
	display: inline-block;
	color: #fff;
	font-weight: normal;
	width: 800px;
	margin: 0;
	padding: 0;
}

h1 span {
	display: inline-block;
	min-width: 250px;
}

h2 {
	font-size: 120%;
	padding: 0;
	margin: 0;
}

.error {
	background: rgba(255, 0, 0, 0.1);
	border: 1px solid rgba(255, 0, 0, 0.2);
	max-width: 400px;
	padding: 10px;
	margin: 10px;
	border-radius: 5px;
}

header img#logo {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
	position: absolute;
	width: 150px;
	top: -34px;
	right: 30px;
}

@media only screen and (max-width: 900px) {
	#app {
		height: calc(100% - 95px);
	}
	header {
		padding: 15px 0 15px 15px;
		height: 65px;
	}
	header h1 {
		font-size: 150%;
		width: 400px;
	}
	header img#logo {
		width: 100px;
		top: -12px;
		right: 20px;
	}
}

@media only screen and (max-width: 600px) {
	#app {
		height: calc(100% - 80px);
	}
	header {
		height: 50px;
	}
	header h1 {
		font-size: 110%;
		width: calc(100% - 300px);
	}
	header img#logo {
		width: 85px;
		top: -12px;
		right: 20px;
	}
	button {
		padding: 9px 15px;
		font-size: 125%;
	}
}

@media only screen and (max-width: 370px) {
	header img#logo {
		display: none;
	}
}
</style>