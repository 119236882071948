import ReconnectingWebSocket from 'reconnecting-websocket';

import EventSystem from './EventSystem.js';

const tokenMap = new Map();

const wshost = (window.location.protocol === 'http:' ? 'ws' : 'wss')
	+ `://${window.location.hostname}:${window.location.port}/api`;
let websocket = new ReconnectingWebSocket(wshost);

const MissingTokenException = () => {
	this.message = 'No token found.';
	this.name = 'MissingTokenException';
};

websocket.onmessage = (msg) => {
	msg = JSON.parse(msg.data);
	if (msg.token) {
		return handleWebsocketReplies(msg);
	}

	if (msg.event) {
		return EventSystem.emit(msg.event, msg.payload);
	}

	if (msg.error) {
		websocket.close();
		return EventSystem.emit('fatal-error', msg.error);
	}

	console.warn(`Unknown message received '${msg}'`);
};

websocket.onclose = () => {
	EventSystem.emit('disconnect');
};

const handleWebsocketReplies = (msg) => {
	if (!tokenMap.has(msg.token)) {
		throw new MissingTokenException();
	}

	let { accept, reject } = tokenMap.get(msg.token);
	tokenMap.delete(msg.token);

	if (msg.error) {
		return reject(msg.error);
	}

	accept(msg.payload);
};

/**
 * Generate a poorly pseudo-random 8-character long string.
 * @returns string
 * @private
 */
const getToken = () => Math.random().toString(36).substr(2, 8);

/**
 * Sleep for a specified number of milliseconds.
 * @param {Number} ms Milliseconds to sleep for.
 * @private
 */
const sleep = async (ms) => new Promise((accept) => setTimeout(accept, ms));

/**
 * Send message to server API and wait for reply with a promise.
 * @param {string}
 * @returns {Promise} Promise resolves with reply.
 * @public
 */
const send = async (type, payload) => {
	let attempts = 0;
	while (websocket.readyState !== WebSocket.OPEN) {
		if (attempts > 10) {
			console.warn(`Waiting for websocket (websocket.readyState=${websocket.readyState}).`);
		}
		await sleep(50 * ++attempts);
	}

	const token = getToken();
	return new Promise((accept, reject) => {
		tokenMap.set(token, { accept, reject });
		const msg = { token, type, payload };
		websocket.send(JSON.stringify(msg));
	});
};

export default { send };