<template>
	<div id="chat">
		<div v-if="patientDescription.length > 1" id="patientBox">
			<h2>Symptombeskrivelse</h2>
			<p>{{ patientDescription }}</p>
		</div>
		<div id="chatLog" ref="chatLog">
			<div class="chatMessage" v-for="msg in chatMessages" :class="msg.author" v-bind:key="msg._id">
				<span class="author">{{ msg.name }}</span>
				<span v-if="msg.createdAt" class="timestamp">{{ msg.createdAt.toLocaleTimeString() }}</span>
				<span class="message">{{ msg.contents }}</span>
			</div>
			<p v-if="error" v-html="error" class="error"></p>
		</div>
		<div id="inputs">
			<input
				:disabled="!chatActive"
				type="text"
				v-focus
				v-model="messageContents"
				v-on:keyup.enter="sendChatMessage()"
			/>
			<button :disabled="!chatActive" v-on:click="sendChatMessage()">Send</button>
		</div>
	</div>
</template>

<script>
import DataBroker from '../helpers/DataBroker.js';
import EventSystem from '../helpers/EventSystem.js';

export default {
	name: 'Chat',
	props: ['patientDescription'],
	data: function() {
		return {
			error: this.error,
			chatMessages: this.chatMessages,
			messageContents: this.messageContents,
			chatActive: this.chatActive
		};
	},
	created() {
		this.chatActive = true;
		this.chatMessages = [];
		EventSystem.on('chat-message', message => {
			message.name =
				message.author === 'PATIENT' ? 'Dig' : message.author === 'CONSULTANT' ? 'Vagtlæge' : '';
			message.author = message.author.toLowerCase();
			message.createdAt = new Date(message.createdAt);
			message.key = Math.random()
				.toString(36)
				.substring(2);
			this.chatMessages.push(message);
			this.scrollToBottomOfChat();
		});

		EventSystem.on('end-chat', () => {
			this.chatActive = false;
		});

		EventSystem.on('disconnect', () => {
			this.chatActive = false;
			this.error =
				'Du har mistet forbindelsen til serveren, og konsultationen er derfor blevet ' +
				'afsluttet. Hvis du ikke var færdig med din konsultation, bliver du nødt til at ' +
				'<a href="/">gå til forsiden</a> og starte forfra. Sørg for at holde vinduet aktivt, ' +
				'imens du er i konsultation.';
		});
	},
	methods: {
		async sendChatMessage() {
			if (this.messageContents.length === 0) return;
			const contents = this.messageContents;
			this.messageContents = '';
			await DataBroker.send('chat-message', { contents });
		},
		scrollToBottomOfChat() {
			// Wait for rerender.
			setTimeout(() => {
				if (this.$refs.chatLog) {
					this.$refs.chatLog.scrollTop = this.$refs.chatLog.scrollHeight;
				}
			});
		}
	},
	directives: {
		focus: {
			inserted: el => el.focus()
		}
	}
};
</script>

<style scoped>
div#chat {
	background: #fff;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding: 10px 10px 2px;
}
div#chat div#patientBox {
	background: #f0f0f0;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 5px;
}
div#chat div#patientBox h2,
div#chat div#patientBox p {
	width: 760px;
	max-width: calc(100vw - 80px);
	margin: 0;
	word-wrap: break-word;
	hyphens: auto;
	overflow: hidden;
}
div#chat div#patientBox h2 {
	font-size: 100%;
	padding-bottom: 5px;
}
div#chatLog {
	padding-top: 10px;
	overflow-y: auto;
	flex-grow: 1;
}
div#chat div.chatMessage {
	line-height: 120%;
}
div#chat div.chatMessage.system {
	text-align: center;
	font-style: italic;
}
div#chat div.chatMessage span.author {
	display: none;
}
div#chat div.chatMessage span.timestamp {
	font-size: 65%;
	color: #888;
	display: block;
}
div#chat div.chatMessage.patient span.message,
div#chat div.chatMessage.consultant span.message {
	display: inline-block;
	text-align: left;
	max-width: 400px;
	word-wrap: break-word;
	hyphens: auto;
	overflow: hidden;
	padding: 8px 16px;
	border-radius: 8px;
}
div#chat div.chatMessage.consultant span.message {
	background: #eee;
}
div#chat div.chatMessage.patient {
	text-align: right;
}
div#chat div.chatMessage.patient span.message {
	margin-left: calc(40% - 32px);
	background: #adb9df;
}
div#chat div#inputs {
	display: flex;
	margin: 10px;
}
div#chat div#inputs input {
	flex-grow: 1;
	border: 1px solid #ccc;
	box-shadow: inset 0 3px 10px rgba(0, 0, 0, 0.1);
}
div#chat div#inputs input:focus {
	outline: none;
}
div#chat div#inputs button {
	border: 0;
	color: #fff;
	background-color: #256575;
	background: linear-gradient(180deg, #12404a 0, #256575);
	padding: 10px 16px;
}

@media only screen and (max-width: 900px) {
	div#chat div#inputs {
		margin: 0;
	}
}

@media only screen and (max-width: 600px) {
	div#chat div#inputs button {
		font-size: 100%;
	}
}
</style>
