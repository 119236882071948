<template>
	<div id="enterCPR">
		<p v-if="error" class="error">{{ error }}</p>
		<p>
			Døve, hørehæmmede og talehæmmede kan kontakte lægevagten i Region Midtjylland via denne chatfunktion.
			Der er lige nu
			<strong>{{ queueLength || 'ingen' }}</strong> i kø.
		</p>
		<p>
			Vagtlægen kan også kontaktes på telefon:
			<a href="tel:+4570113131">70 11 31 31</a>.
		</p>
		<h2>Åbningstider</h2>
		<p>
			<em>Hverdage (mandag til fredag):</em>
			<br />16.00&mdash;08.00
		</p>
		<p>
			<em>Grundlovsdag, juleaftensdag, nytårsaftensdag, lørdage og søn- og helligdage:</em>
			<br />00.00&mdash;24.00
		</p>
		<div id="inputs">
			<div>
				<label for="cpr">CPR-nummer</label>
				<input
					id="cpr"
					@keypress="cprInputHandler($event)"
					@keyup="cprValidator($event)"
					v-model="cprNumber"
					required
					pattern="[0-9]*"
					inputmode="numeric"
					placeholder="CPR-nummer"
					type="number"
					autocomplete="off"
				/>
			</div>
			<div>
				<label for="description">Symptombeskrivelse</label>
				<textarea
					id="description"
					v-model="description"
					placeholder="Beskriv kort dine symptomer"
					:maxlength="descriptionMaxLength"
					rows="5"
				></textarea>
			</div>
			<div style="max-width:415px">
				<button :disabled="!validCpr" v-on:click="joinQueue()">Gå i kø</button>
				<span
					style="float:right;font-size:75%;"
					v-if="description.length > descriptionMaxLength - 50"
				>{{ description.length }}/{{ descriptionMaxLength }} tegn brugt.</span>
			</div>
		</div>
	</div>
</template>

<script>
import DataBroker from '../helpers/DataBroker.js';
import EventSystem from '../helpers/EventSystem.js';

export default {
	name: 'EnterCPR',
	props: ['error'],
	data: function() {
		return {
			queueLength: this.queueLength,
			validCpr: this.validCpr || false,
			cprNumber: this.cprNumber || '',
			description: this.description || '',
			descriptionMaxLength: 250
		};
	},
	created() {
		this.getQueueLength();

		this.validCpr = false;
		// We do this every 28 seconds, because if memory serves right, Linux likes closing idle
		// sockets after 30 seconds.
		setInterval(this.getQueueLength, 1000 * 28);
	},
	methods: {
		joinQueue() {
			this.$emit('join-queue', this.cprNumber, this.description);
		},
		async updateQueueLength(queueLength) {
			this.queueLength = typeof queueLength === 'number' ? queueLength : '?';
		},
		async getQueueLength() {
			const { queueLength } = await DataBroker.send('get-queue-length');
			this.updateQueueLength(queueLength);
		},
		cprInputHandler(e) {
			if (['e', '+', '-', '.', ','].includes(e.key.toLowerCase())) {
				return e.preventDefault();
			}

			if (e.target.value.length >= 10) {
				return e.preventDefault();
			}
		},
		cprValidator(e) {
			this.validCpr = e.target.value.match(/^[0-9]{6}\-?[0-9a-zA-Z]{4}$/);
		}
	}
};
</script>

<style scoped>
div#enterCPR {
	max-width: calc(100% - 20px);
	margin: 0 10px;
}

label {
	margin: 10px 0;
	display: block;
	font-weight: bold;
}
input,
textarea {
	font: inherit;
	border: 0;
	border-radius: 5px;
	width: 150px;
	resize: none;
}

textarea {
	width: 400px;
	max-width: calc(100% - 20px);
}

button {
	color: rgba(255, 255, 255, 1);
	font-weight: bold;
	border: 0;
	border-radius: 5px;
	background-color: #256575;
	background: linear-gradient(180deg, #12404a 0, #256575);
	margin-top: 5px;
}

button[disabled] {
	color: rgba(255, 255, 255, 0.5);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input[type='number'] {
	-moz-appearance: textfield;
	-webkit-appearance: none;
}
</style>
